import PropTypes from "prop-types";
import React from "react";
import { useForm } from "react-hook-form";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import Button from "./Button";
import { Modal } from "react-morphing-modal";
import Logo from "../images/Golden.png";

export default function LandingPage({ className, style, modalProps }) {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
    console.log(data, e);
  };
  return (
    <Modal className={`my-auto ${className}`} style={style} {...modalProps}>
      <img src={Logo} className="w-3/4 md:w-1/3 m-auto" alt="" />

        <h3 className="mt-3 text-center base-golden-center">
          Envie-nos uma mensagem!
        </h3>

      <div className="form mx-5">
        <form onSubmit={handleSubmit(onSubmit)} className="w-full lg:w-7/12 mx-auto">
          <input
            name="Nome"
            ref={register}
            placeholder="Nome"
            className="my-5"
          />
          <input
            name="Email"
            ref={register}
            placeholder="E-mail"
            type="email"
            className="my-5"
          />
          <input
            name="Telefone"
            ref={register}
            placeholder="Telefone"
            className="my-5"
          />
          <textarea
            type="textarea"
            name="Mensagem"
            ref={register}
            rows={5}
            placeholder="Mensagem"
          />
          <Button className="w-full my-5" type="submit">
            Enviar
          </Button>
        </form>
      </div>
    </Modal>
  );
}

LandingPage.propTypes = {
  className: PropTypes.string,
  modalProps: PropTypes.any,
  style: PropTypes.object
};
