import Link from "gatsby-link";
import React from "react";
import Logo from "../images/Logo.png";
import Facebook from "../images/facebook.png";
import Instagram from "../images/instagram.png";
import Linkedin from "../images/linkedin.png";

function Footer() {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 md:mt-4 p-4">
        <div className="col-span-1 col-start-1 self-center">
          <img src={Logo} className="w-2/5 m-auto" />
        </div>

        <div className="col-span-1 lg:col-start-2">
          <div>
            <div className="flex mx-auto">
              <box-icon name="map" color="#f9c000"></box-icon>
              <p className="ml-3 base-title text-golden mb-3 uppercase">
                Base operacional
              </p>
            </div>
            <div className="ml-10 mb-3">
              <p className="mb-0">Rua 4100, 321</p>
              <p>Itacolomi, Piçarras/SC</p>
            </div>
            <div className="flex my-3">
              <box-icon name="whatsapp" color="#f9c000" type="logo" />
              <p className="flex text-base items-center ml-3">
                (47) 98897-0668
              </p>
            </div>
          </div>
          <div>
            <div className="flex mx-auto">
              <box-icon name="map" color="#f9c000"></box-icon>
              <p className="ml-3 base-title text-golden mb-3 uppercase">
                Centro administrativo
              </p>
            </div>
            <div className="ml-10 mb-3">
              <p className="mb-0">Rua Iririú, 847</p>
              <p>Iririú, Joinville/SC</p>
            </div>
            <div className="flex my-3">
              <box-icon name="phone" color="#f9c000" />
              <p className="flex text-base items-center ml-3">(47) 3170-1115</p>
            </div>
          </div>
          <div className="flex mb-2">
            <box-icon name="envelope" color="#f9c000" />
            <p className="flex text-base items-center ml-3">
              comercial@goldentecnologia.ind.br
            </p>
          </div>
        </div>

        <div className="col-span-1 lg:col-start-3 grid">
          <h3 className="base-title text-golden mb-3 uppercase">
            Serviços Prestados
          </h3>
          <Link to="/execucaodeobras" className="text-base mb-2 navbar">
            Execução de Obras
          </Link>
          <Link to="/projetos" className="text-base mb-2 navbar">
            Projetos Arquitetônicos
          </Link>
          <Link to="/projetos" className="text-base mb-2 navbar">
            Projetos Estruturais
          </Link>
          <Link to="/projetos" className="text-base mb-2 navbar">
            Projetos Elétricos
          </Link>
          <Link to="/projetos" className="text-base mb-2 navbar">
            Projetos Hidráulicos
          </Link>
          <Link to="/projetos" className="text-base mb-2 navbar">
            Projetos Preventivos
          </Link>
          <Link to="/premoldados" className="hidden text-base mb-2 navbar">
            Pré-Moldados
          </Link>
          <Link to="/sondagens/st" className="text-base mb-2 navbar">
            Sondagem à Trado (ST)
          </Link>
          <Link to="/sondagens/sptt" className="text-base mb-2 navbar">
            Sondagem à Percussão com Torque (SPT-T)
          </Link>
        </div>
      </div>
      <section className="p-5 flex mt-6" style={{ backgroundColor: "#1a1919" }}>
        <p className="text-golden base-title uppercase ml-2 md:ml-32">
          Redes sociais
        </p>
        <div className="flex ml-10">
          <a
            href="https://www.facebook.com/Golden-Tecnologia-em-Constru%C3%A7%C3%A3o-109639434053155/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={Facebook} />
          </a>
          <a
            className="ml-3"
            href="https://www.instagram.com/golden.tecnologia"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={Instagram} />
          </a>
          <a
            className="ml-3"
            href="https://www.linkedin.com/company/golden-tecnologia-em-constru%C3%A7%C3%A3o/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={Linkedin} />
          </a>
        </div>
      </section>
      <section className="p-4 md:mr-32 text-right text-white">
        © 2020 | Desenvolvido por{" "}
        <a href="https://www.linkedin.com/in/steffany-camila/">
          Steffany Camila
        </a>
      </section>
    </div>
  );
}

export default Footer;
