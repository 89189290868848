import Link from "gatsby-link";
import React, { useState } from "react";
import Logo from "../images/Golden.png";
import Facebook from "../images/facebook.png";
import Instagram from "../images/instagram.png";
import Linkedin from "../images/linkedin.png";
import Navigation from "./Navigation";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header id="header-golden">
      <section className="hidden lg:flex bg-white p-5 md:px-24">
        <Link to="/" className="m-auto w-1/3">
          <img src={Logo} />
        </Link>
        <div className="flex ml-6">
          <div>
            <div className="flex mx-auto">
              <box-icon name="map" color="#f9c000"></box-icon>
              <b className="flex text-base items-center ml-3">
                Base operacional
              </b>
            </div>
            <div className="ml-10 mb-3">
              <p className="mb-0">Rua 4100, 321</p>
              <p>Itacolomi, Piçarras/SC</p>
            </div>
            <div className="flex mb-3">
              <box-icon name="phone" color="#f9c000" />
              <b className="flex text-base items-center ml-3">(47) 98897-0668</b>
            </div>
            
          </div>
          <div className="ml-8">
            <div className="flex mx-auto">
              <box-icon name="map" color="#f9c000"></box-icon>
              <b className="flex text-base items-center ml-3">
                Centro administrativo
              </b>
            </div>
            <div className="ml-10 mb-3">
              <p className="mb-0">Rua Iririú, 847</p>
              <p>Iririú, Joinville/SC</p>
            </div>
            <div className="flex">
              <box-icon name="phone" color="#f9c000" />
              <b className="flex text-base items-center ml-3">(47) 3170-1115</b>
            </div>
          </div>
        </div>

        <div className="flex self-center m-auto">
          <a
            href="https://www.facebook.com/Golden-Tecnologia-em-Constru%C3%A7%C3%A3o-109639434053155/"
            rel="noopener noreferrer"
            target="_blank"
          >
          <img src={Facebook} />
          </a>
          <a
            className="ml-3"
            href="https://www.instagram.com/golden.tecnologia"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={Instagram} />
          </a>
          <a
            className="ml-3"
            href="https://www.linkedin.com/company/golden-tecnologia-em-constru%C3%A7%C3%A3o/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={Linkedin} />
          </a>
        </div>
      </section>

      <div className="flex">
        <Link to="/" className="block lg:hidden w-1/2 m-1">
          <img src={Logo} />
        </Link>
        <button
          className="block lg:hidden ml-auto px-3 py-2 text-white"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <box-icon name="menu" color="#f9c000">
            <title>Menu</title>
          </box-icon>
        </button>
      </div>

      <div className="items-center justify-between">
        <Navigation {...{ isExpanded}} />
      </div>
    </header>
  );
}

export default Header;
