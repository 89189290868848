typeof window !== "undefined" && require("boxicons");
import Link from "gatsby-link";
import PropTypes from "prop-types";
import React from "react";

const Navigation = ({ isExpanded }) => (
  <nav
  id="navGolden"
    className={`nav p-6 justify-center md:inline-flex ${
      isExpanded ? `block` : `hidden`
    }`}
  >
    <ul className="md:flex">
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/empresa">A empresa</Link>
      </li>
      <li className="icon-margin" aria-haspopup="true">
        Serviços <box-icon size="xs" name="chevron-down" color="#fff" />
        <ul id="dropdownNav" className="row">
          <li className="col-md-6 d-flex flex-column">
            <Link to="/execucaodeobras">Execução de obras</Link>
          </li>
          <li className="hidden col-md-6 d-flex flex-column">
            <Link to="/premoldados">Pré-moldados</Link>
          </li>
          <li className="col-md-6 d-flex flex-column">
            <Link to="/projetos">Projetos</Link>
          </li>
          <li className="col-md-6 d-flex flex-column">
            <Link to="/sondagens">Sondagens</Link>
          </li>
          <li className="col-md-6 d-flex flex-column">
            <Link to="/topografia">Topografia</Link>
          </li>
          <li className="col-md-6 d-flex flex-column">
            <Link to="/percolacao">Percolação e ensaio CBR</Link>
          </li>
        </ul>
      </li>
      <li className="icon-margin">
        <Link to="/obras" className="b-2">
          Obras executadas{" "}
          <box-icon size="xs" name="chevron-down" color="#fff" />
        </Link>
        <ul id="dropdownNav2" className="pl-3">
          <li className="col-md-6 d-flex flex-column">
            <Link to="/obras">Palm Coast</Link>
          </li>
          <li className="col-md-6 d-flex flex-column">
            <Link to="/obras">Apple Beach</Link>
          </li>
          <li className="col-md-6 d-flex flex-column">
            <Link to="/obras">Jacques Beach</Link>
          </li>
          <li className="col-md-6 d-flex flex-column">
            <Link to="/obras">Memphis Residence</Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to="/orcamento">Orçamento</Link>
      </li>
      <li>
        <Link to="/contato">Contato</Link>
      </li>
    </ul>
  </nav>
);

Navigation.propTypes = {
  isExpanded: PropTypes.bool,
};

export default Navigation;
