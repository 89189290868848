import PropTypes from "prop-types";
import React, { useEffect } from "react";
import WhatsAppIcon from "../images/whatsappp.png";
import Message from "../images/bate-papo.png";
import Footer from "./Footer";
import Header from "./header";
import LandingPage from "./LandingPage";
import { useModal } from "react-morphing-modal";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Layout({ children }) {
  const { modalProps, getTriggerProps } = useModal({
    background: "#fff",
  });
  
  if (typeof window !== 'undefined'){
  useEffect(() => {
    const handleScroll = () => {   
        if(window.scrollY > document.getElementById("header-golden").offsetHeight - document.getElementById("navGolden").offsetHeight)
        {
          document.getElementById("navGolden").style.position = 'fixed'
          document.getElementById("navGolden").style.overflow = 'hidden'
          document.getElementById("navGolden").style.top = '0'
          document.getElementById("dropdownNav").style.position = 'fixed'
          document.getElementById("dropdownNav2").style.position = 'fixed'
        }
        else{
          document.getElementById("navGolden").style.position = 'static'
          document.getElementById("navGolden").style.overflow = 'auto'
          document.getElementById("dropdownNav").style.position = ''
          document.getElementById("dropdownNav2").style.position = ''
        }
      }
    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [window.scrollY]);
  }
  
  return (
    <div className="flex flex-col min-h-screen align-middle text-gray-900">
      <ToastContainer />
      <Header />
      <main className="flex flex-col flex-1 md:justify-center mx-auto md:px-8 w-full m-4 md:my-6">
        {children}
      </main>

      <LandingPage modalProps={modalProps} />
      <button
        style={{ bottom: "20px", right: "90px" }}
        className="block fixed tooltip"
        {...getTriggerProps()}
      >
        <span className="tooltiptext">Envie-nos uma mensagem!</span>
        <img src={Message} style={{ width: "50px" }} />
      </button>

      <a
        href="https://wa.me/47988309441"
        rel="noopener noreferrer"
        target="_blank"
        className="block fixed bottom-0 right-0 pb-5 mr-5 tooltip"
      >
        <span className="tooltiptext">Entre em contato!</span>
        <img src={WhatsAppIcon} style={{ width: "50px" }} />
      </a>

      <footer className="footer">
        <Footer />
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
